// MAIN_TITLE
import en from './en'
import ru from './ru'
import errors from './errors'

// Modules
import pdd from '@/modules/pdd/config/locale'
import gis from '@/modules/gis/config/locale'
import info from '@/modules/info/config/locale'
import user from '@/modules/user/config/locale'
import mrir from '@/modules/mrir/config/locale'
import about from '@/modules/about/config/locale'
import ksodd from '@/modules/ksodd/config/locale'
import asudd from '@/modules/asudd/config/locale'
import tsodd from '@/modules/tsodd/config/locale'
import tsoddSecondary from '@/modules/tsodd-secondary/config/locale'
import complexTable from '~/complex-table/config/locale'
import filesComments from '~/files-comments/config/locale'
import layerOptions from '~/layer-options/config/locale'
import reports from '@/modules/reports/config/locale'
import its from '@/modules/its-control/config/locale'
import parking from '@/modules/parking/config/locale'
import modeling from '@/modules/modeling/config/locale'
import notification from '@/extensions/notification/locale'
import scenario from '@/modules/scenario/config/locale'
import licenses from '@/modules/licenses/config/locale'
import explorer from '@/modules/explorer/config/locale'
import emergency from '@/modules/emergency/config/locale'
import roadWorks from '@/modules/road-works/config/locale'
import regulations from '@/modules/regulations/config/locale'
import digitalTwin from '@/modules/digital-twin/config/locale'
import monitoringPt from '@/modules/monitoring-pt/config/locale'
import enumRegistry from '@/modules/enum-registry/config/locale'
import isochroneAnalysis from '@/modules/isochrone-analysis/config/locale'
import transportSituation from '@/modules/transport-situation/config/locale'
import attributesManager from '@/modules/attributes-manager/config/locale'
import modelingDtBook from '@/modules/sub-modules/modeling-dt-book/config/locale'
import settings from '@/modules/settings/config/locale'

// Components
import drawPanel from '@/components/draw-panel/config/locale'

export default {
  ru: Object.assign(
    ru,
    its.ru,
    gis.ru,
    pdd.ru,
    mrir.ru,
    user.ru,
    info.ru,
    asudd.ru,
    about.ru,
    ksodd.ru,
    tsodd.ru,
    errors.ru,
    parking.ru,
    settings.ru,
    reports.ru,
    modeling.ru,
    explorer.ru,
    licenses.ru,
    scenario.ru,
    emergency.ru,
    drawPanel.ru,
    roadWorks.ru,
    regulations.ru,
    digitalTwin.ru,
    complexTable.ru,
    filesComments.ru,
    layerOptions.ru,
    notification.ru,
    enumRegistry.ru,
    monitoringPt.ru,
    tsoddSecondary.ru,
    transportSituation.ru,
    isochroneAnalysis.ru,
    transportSituation.ru,
    attributesManager.ru,
    modelingDtBook.ru
  ),
  en: Object.assign(
    en,
    gis.en,
    pdd.en,
    its.en,
    user.en,
    mrir.en,
    info.en,
    about.en,
    ksodd.en,
    asudd.en,
    tsodd.en,
    errors.en,
    parking.en,
    reports.en,
    modeling.en,
    settings.en,
    explorer.en,
    licenses.en,
    scenario.en,
    drawPanel.en,
    roadWorks.en,
    emergency.en,
    complexTable.en,
    filesComments.en,
    layerOptions.en,
    digitalTwin.en,
    regulations.en,
    notification.en,
    enumRegistry.en,
    monitoringPt.en,
    tsoddSecondary.en,
    isochroneAnalysis.en,
    transportSituation.en,
    transportSituation.en,
    attributesManager.en,
    modelingDtBook.en
  )
}
